/* eslint-disable @typescript-eslint/no-explicit-any */

/** **************************************************************************************************
 * NOTE: If you add an polyfill here, remember to mark it with some 'unicorn/expiring-todo-comments'
 * to make clear how long it will be necessary to stay here.
 ***************************************************************************************************/

/*
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/**
 * IE 11 support
 * https://github.com/aspnet/JavaScriptServices/wiki/Supporting-Internet-Explorer-11-(or-older)
 */
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/reflect';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';

/**
 * structuredClone is only available from Safari 15.4 (See https://caniuse.com/?search=structuredClone)
 */
import 'core-js/actual/structured-clone';

/**
 * https://github.com/angular/angular/blob/master/packages/zone.js/README.md
 */
import 'zone.js';

/**
 * More or less this polyfill:
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#polyfill
 */
if (!Element.prototype.matches as unknown) {
	Element.prototype.matches = (
		(Element.prototype as any).matchesSelector ||
		(Element.prototype as any).mozMatchesSelector ||
		(Element.prototype as any).msMatchesSelector ||
		(Element.prototype as any).oMatchesSelector ||
		(Element.prototype as any).webkitMatchesSelector
	);
}

/**
 * PLANO-7002 https://github.com/angular/angular/issues/24094
 */
if (document.body.style.animation as string | undefined === undefined && CSSStyleDeclaration as unknown) {
	CSSStyleDeclaration.prototype.animation = '';
}
if (document.body.style['animation-name' as any] as unknown === undefined && CSSStyleDeclaration as unknown) {
	CSSStyleDeclaration.prototype['animation-name' as any] = '';
	CSSStyleDeclaration.prototype['animationName'] = '';
}
if (document.body.style['animation-duration' as any] as unknown === undefined && CSSStyleDeclaration as unknown) {
	CSSStyleDeclaration.prototype['animation-duration' as any] = '';
	CSSStyleDeclaration.prototype['animationDuration'] = '';
}
if (document.body.style['animation-play-state' as any] as unknown === undefined && CSSStyleDeclaration as unknown) {
	CSSStyleDeclaration.prototype['animation-play-state' as any] = '';
	CSSStyleDeclaration.prototype['animationPlayState'] = '';
}

// // remove the first instance of an item inside an array  (extends native array object)
// if (!(Array.prototype as any).remove) {
// 	// eslint-disable-next-line jsdoc/require-jsdoc
// 	(Array.prototype as any).remove = function remove <T extends number>(item : T) : T[] | undefined {
// 		if (!(this || Array.isArray(this))) {
// 			// eslint-disable-next-line unicorn/error-message
// 			throw new TypeError();
// 		}

// 		if (this.includes(item)) {
// 			this.splice(this.indexOf(item), 1);
// 			return this;
// 		}

// 		// handles cases where item is a finite index and element at given index is defined
// 		if (typeof this[item] !== 'undefined' && item >= 0 && Number.isFinite(item)) {
// 			this.splice(item, 1);
// 			return this;
// 		}
// 	};
// }
